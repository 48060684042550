import Navigation from "./navAndFooter/Navigation"
import { useState, useEffect } from "react"
import './styles/bookCall.scss'
import Footer from "./navAndFooter/Footer";
import survey from './utils/survey.json';
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import axios from "axios";
import moment from "moment";
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const buttonStyle = {
  width: '20%',
  color: '#09A7E0',
  "@media (max-width: 690px)": {
    width: '90%',
  }
}

export default function BookCall() {
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [postcode, setPostcode] = useState('');
  const [industry, setIndustry] = useState('');
  const [message, setMessage] = useState('');
  const [role, setRole] = useState('');
  const [formComplete, setFormComplete] = useState(true)
  const [other, setOther] = useState(false)
  const [email, setEmail] = useState('')
  const url = 'https://dev.pmwwebdesign.com';

  useEffect(() => { window.scrollTo(0, 0) }, [])

  const handleSurvey = (answer) => {
    setAnswer('')
    const regex = '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$'
    const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // navigates to the calendar page
    if (currentIndex === survey.length - 1) {
      //handles the data to the mongodb
      if (postcode === '' || industry === '' || role === '' || email === '') {
        setMessage('All fields are required')
        setFormComplete(false)
        setTimeout(() => {
          setFormComplete(true)
          setMessage('')
        }, 2500)
      } else if (!postcode.match(regex)) {
        let alertId = document.getElementById('postcode_alert')
        setMessage('Postcode must have a space');
        alertId.style.display = 'inline';
        setTimeout(() => {
          alertId.style.display = 'none';
          setMessage('')
        }, 2500)
      } else if (!email.match(emailReg)) {
        let alertId = document.getElementById('email_alert');
        setMessage('email has the wrong format');
        alertId.style.display = 'inline';
        setTimeout(() => {
          alertId.style.display = 'none';
          setMessage('')
        }, 2500)
      }
      else {
        navigate('/book-a-call/calendar')

        let data = {
          postcode: postcode,
          industry: industry,
          date: moment().format('DD-MM-YYYY'),
          jobRole: role,
          email: email,
        }
        axios.post(`${url}/ssas/`, data)
          .then((res) => {
            navigate('/book-a-call/calendar')
          }).catch((err) => {
            console.log(`something went wrong: ${err}`)
          })
      }

    } else {
      // handles answer yes to next question, no shows the message
      if (answer === 'no') {
        setAnswer(survey[currentIndex].no)
      } else {
        setCurrentIndex(currentIndex + 1);
        // document.getElementById('radio_form').value ='';
      }
    }
  }

  const handleSelect = (select) => {
    setIndustry('')
    setOther(false)
    if (select !== 'Other') {
      setIndustry(select)
    } else {
      setOther(true)
    }
  }


  return (
    <main className='call__main'>
      <Navigation page='call' />
      <section className='calendar__main'>
        <article className='bokking_top'>
          <div className='booking_top_section'>
            <h2>Does your company qualify for SSAS?</h2>
            <p><CheckCircleOutlineIcon sx={{ color: '#EB9624', marginRight: '2%' }} fontSize="large" /> Do you have an established UK limited company?</p>
            <p><CheckCircleOutlineIcon sx={{ color: '#EB9624', marginRight: '2%' }} fontSize="large" /> Has that company been actively trading for at least the last 12 months?</p>
            <p className='book--cta'>If you anserwed yes, it's time to have a call. Just fill in the form bellow and get booked in.</p>
          </div>
        </article>
        <article className='bokking_details'>
          <section className='details__text'>
            <h2>Please answer these questions prior to booking a call</h2>

            <section className='ssas_form'>
              {currentIndex < survey.length - 1 ? (
                <>
                  <Typography id="ssas_survey_radio" variant='subtitle' sx={{ margin: '3% auto' }}>{survey[currentIndex].question}</Typography>
                  <div className='form__buttons'>
                    <Button onClick={() => handleSurvey('yes')} style={buttonStyle} variant='outlined' className="form--btn">{survey[currentIndex].answers[0]}</Button>
                    <Button onClick={() => handleSurvey('no')} style={buttonStyle} variant='outlined' className="form--btn">{survey[currentIndex].answers[1]}</Button>
                  </div>
                </>
              ) : (
                <section className='form_questionaire'>
                  <Typography id="ssas_survey_radio" variant='h6'>{survey[currentIndex].question}</Typography>
                  <label>Please let us know your postcode<span>*</span><Tooltip title='XX1 2XX, X12 2XX'><HelpIcon fontSize="10px" sx={{ color: '#A8A8A8' }} /></Tooltip></label>
                  <strong id='postcode_alert'>{message}</strong>
                  <TextField id="standard-basic" label="Your Postcode" variant="standard"
                    sx={{ margin: '1% 0', width: '100%' }}
                    onChange={(e) => setPostcode(e.target.value)} />
                  <label>What industry does your business operate in?<span>*</span></label>
                  <select
                    labelId="select-label"
                    id="industry select"
                    label="Please select the industry of your business"
                    sx={{ marginTop: '15%', width: '100%' }}
                    value={industry}
                    onChange={(e) => handleSelect(e.target.value)}
                  >
                    <option value='Accomodation and Food Service Activities'>Accomodation and Food Service Activities</option>
                    <option value='Agriculture, Forestry and Fishing'>Agriculture, Forestry and Fishing</option>
                    <option value='Water Supply'>Water Supply</option>
                    <option value='Real State Activities'>Real State Activities</option>
                    <option value='Human Health and Social Work Activities'>Human Health and Social Work Activities</option>
                    <option value='Arts, Entertainment and Recreation'>Arts, Entertainment and Recreation</option>
                    <option value='Construction'>Construction</option>
                    <option value='Financial Services'>Financial Services</option>
                    <option value='Manufacturing'>Manufacturing</option>
                    <option value='Education'>Education</option>
                    <option value='Scientific and Technical Activities'>Scientific and Technical Activities</option>
                    <option value='Information and Communication'>Information and Communication</option>
                    <option value='Administrative and Support Service Activities'>Administrative and Support Service Activities</option>
                    <option value='Transportation and Storage'>Transportation and Storage</option>
                    <option value='Retail'>Retail</option>
                    <option value='Manufacturing'>Manufacturing</option>
                    <option value='Technology'>Technology</option>
                    <option value='Engineering'>Engineering</option>
                    <option value='Other'>Other</option>
                  </select>
                  {other ? (
                    <TextField id="standard-basic" label="Your Business Industry" variant="standard"
                      sx={{ margin: '1% 0', width: '100%' }}
                      onChange={(e) => setIndustry(e.target.value)} />
                  ) : null}
                  <label>Your Job Role in the business <span>*</span></label>
                  <TextField id="standard-basic" label="Your Job Role" variant="standard"
                    sx={{ margin: '1% 0', width: '100%' }}
                    onChange={(e) => setRole(e.target.value)} />
                  <label>What is your email address <span>*</span></label>
                  <strong id='email_alert'>{message}</strong>
                  <TextField id="standard-basic" label="Email address" variant="standard"
                    sx={{ margin: '1% 0', width: '100%' }}
                    onChange={(e) => setEmail(e.target.value)} />
                  {formComplete ? (
                    <Button onClick={handleSurvey} color='primary' variant='outlined' sx={{ margin: '5% auto' }}>Book A Call</Button>
                  ) : (
                    <strong id='message__alert'>{message}</strong>
                  )}
                </section>
              )}
            </section>

            {answer === '' ? null : (
              <div className="answer__div">
                <h3>{answer}</h3>
                {currentIndex === 0 ? (
                  <a href='https://blueprint.ssas-consultant.co.uk' target='_blank' rel="noreferrer">SSAS Education program</a>
                ) : null}
              </div>
            )}
          </section>
        </article>
      </section>
      <Footer />
    </main>
  )
}