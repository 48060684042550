import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Button ({size}){
  const [style, setStyle] = useState({})
  const navigate = useNavigate()

  useEffect(() => {

    if (size === 'small'){
      setStyle({minWidth: '30px', borderRadius: '0.5rem', fontSize: '17px', color: '#fff'})
    } else if (size === 'large'){
      setStyle({minWidth: '60px', borderRadius: '0.5rem', fontSize: '30px'})
    } 
  },[size])


  return(
    <button className="book_call_button" onClick={() => navigate('/book-a-call')} style={style}>Book a call</button>
  )
}