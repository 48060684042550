const getDate = () => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();

  let finalDate = `${year}-${month}`

  return finalDate;
}

export {getDate}