import Footer from './navAndFooter/Footer'
import Navigation from './navAndFooter/Navigation'
import './styles/infoPage.scss'
import Button from './utils/Button';
import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import thumb from './images/WThumb.jpg'
import { useState } from 'react';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";

export default function InfoPage() {
  const [value, setValue] = useState(0)

  useEffect(() => { window.scrollTo(0, 0) }, [])

  const open = (val) => {
    if (val == value){
      setValue(0)
    } else{
      setValue(parseInt(val))
    }
  }


  return (
    <main className='info_page_main'>
      <Navigation page='info' />
      <section className='info__main'>
        <article className='what__ssas'>
          <h2>What is SSAS?</h2>
          <p>SSAS is a pension structure created specifically for UK SME business owners, allowing a connection between your business and your pension, offering access to new capital and with effective tax management, can help generate more profits and wealth in a far bigger context</p>
        </article>
        <section className='video__wrapper'>
          <ReactPlayer url='https://youtu.be/ImKLta6PPik' controls style={{ aspecRatio: '0.5' }} className='react--player' light={thumb} />
          <div className='accordion'>
            <h3 onClick={() => open(1)}>Connecting Pension & Business <span>{value === 1 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
            {value === 1 ? (
              <p>SSAS recognises that you should have the scope to make strategic decisions about your pension.</p>
            ): null}
            <hr/>
            <h3 onClick={() => open(2)}>SSAS gives you control <span>{value === 2 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
            {value === 2 ? (
              <p>You will have full control and flexibility to use it the way you want and choose to.</p>
            ): null}
            <hr/>
            <h3 onClick={() => open(3)}>Member directed scheme <span>{value === 3 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
            {value === 3 ? (
              <p>All decisions and control rest only with the SSAS members.</p>
            ): null}
            <hr/>
            <h3 onClick={() => open(4)}>SSAS is a Trust Structure <span>{value === 4 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
            {value === 4 ? (
              <p>Which means that the use of assets and the ownership of those assets are separated.</p>
            ): null}
            <hr/>
          </div>
        </section>
        <section className='book_call'>
          <div className='call__inner'>
            <h2>Do you want to take control of your pension now?</h2>
            <Button size='large' />
          </div>
        </section>
      </section>
      <Footer />
    </main>
  )
}