import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './components/Home';
import BookCall from './components/BookCall';
import InfoPage from './components/InfoPage';
import Who from './components/Who';
import Calendar from './components/Calendar';
import Privacy from './components/Privacy';
import Faq from './components/Faq';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='book-a-call' element={<BookCall />} />
          <Route path='book-a-call/calendar' element={<Calendar />} />
          <Route path='about-ssas' element={<InfoPage />} />
          <Route path='about-us' element={<Who />} />
          <Route path='privacy-policy' element={<Privacy />} />
          <Route path='faq' element={<Faq />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
