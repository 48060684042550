import Navigation from "./navAndFooter/Navigation";
import './styles/home.scss';
import Button from "./utils/Button";
import { Divider } from "@mui/material";
import Card1 from './images/card1.jpg';
import Card2 from './images/square1.jpg';
import Card3 from './images/Card3.jpg';
import Card4 from './images/Card4.jpg';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Footer from "./navAndFooter/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Timeline from "./Timeline";
import thumb from './images/videoThumb.png';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import ReactPlayer from 'react-player';

export default function Home() {
    const navigate = useNavigate();
    const [value, setValue] = useState(0)

    useEffect(() => { window.scrollTo(0, 0) }, [])

    const open = (val) => {
        if (val == value) {
            setValue(0)
        } else {
            setValue(parseInt(val))
        }
    }

    return (
        <main className='home'>
            <Navigation page='home' />
            <section className='home__main'>
                <section className='top__text'>
                    <h2>Discover the power of SSAS</h2>
                    <h3>The only HMRC Approved pension <br /> designed specifically for SME Businesses</h3>
                </section>
                <section className='squares'>
                    <section className='image__row'>
                        <img src={Card1} alt='SME' />
                        <article>
                            <h4 className='square__head'>What is SSAS?</h4>
                            <p className='square__text'>SSAS is a Small Self-Administered Scheme type of pension offering designed for business owners and key company employees.</p>
                            <p className='square__text'>It offers significant flexibility in terms of investment and administration, making it a popular choice for those looking to take control of their retirement planning.</p>
                            <p className='square__text'>Unlike traditional pension schemes, a SSAS provides you with the autonomy to choose a wide range of investments and manage the scheme directly.</p>
                        </article>
                    </section>
                    <section className='image__row'>
                        <img src={Card2} alt='SME' />
                        <article>
                            <h4 className='square__head'>Who is it for?</h4>
                            <p className='square__text'><strong>Business Owners:</strong> Especially those who want to invest in their own company or benefit from more flexible pension arrangements.</p>
                            <p className='square__text'><strong>Key Employees:</strong>  Senior employees who want to have more control over their retirement planning.</p>
                            <p className='square__text'><strong>Small to Medium Enterprises (SMEs):</strong> Companies that want to provide pension benefits with additional investment opportunities.</p>
                        </article>
                    </section>
                    <section className='image__row'>
                        <img src={Card4} alt='HMRC' />
                        <article>
                            <h4 className='square__head'>How Does It Work?</h4>
                            <p className='square__text'><strong>Trustee-Based:</strong> A SSAS is established under a trust, which means it is managed by a group of trustees, typically including the scheme members.</p>
                            <p className='square__text'><strong>Investment Choices:</strong> Members can invest in a diverse array of assets, including commercial property, shares, bonds, and even their own company's shares.</p>
                            <p className='square__text'><strong>Administration:</strong> The scheme is administered by the trustees, who are responsible for ensuring compliance with pension regulations and managing the scheme's investments.</p>
                        </article>
                    </section>
                    <section className='image__row'>
                        <img src={Card3} alt='SME' />
                        <article>
                            <h4 className='square__head'>Why Should I Consider?</h4>
                            <p className='square__text'><strong>Tax Benefits:</strong> As an HMRC-approved pension scheme, a SSAS benefits from a tax-free environment concerning Corporation Tax, Capital Gains Tax, Income Tax, and Inheritance Tax. Each SSAS must be pre-approved and registered with HMRC to maintain these advantages.</p>
                            <p className='square__text'><strong>Flexibility:</strong> You have the freedom to select and manage investments based on your preferences and retirement goals.</p>
                            <p className='square__text'><strong>Borrowing Power:</strong> A SSAS can borrow money to make investments, such as purchasing property, provided the borrowing is within the scheme’s borrowing limits.</p>
                        </article>
                    </section>
                </section>
                <section className='book_call'>
                    <div className='call__inner'>
                        <h2>Want to know more?</h2>
                        <Button size='large' />
                    </div>
                </section>
                <section className="video__sect">
                    <h2>What are the benefits?</h2>

                    <section className='video__wrapper'>
                        <ReactPlayer url='https://vimeo.com/951564802/d3dae6ddd5?share=copy' controls style={{ aspecRatio: '0.5' }} className='react--player' light={thumb} />
                        <div className='accordion'>
                            <h3 onClick={() => open(1)}>Control and Flexibility <span>{value === 1 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
                            {value === 1 ? (
                                <p>SSAS members have significant control over their pension investments and can adapt their strategy as their circumstances change.</p>
                            ) : null}
                            <hr />
                            <h3 onClick={() => open(2)}>Investment Opportunities <span>{value === 2 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
                            {value === 2 ? (
                                <p>A SSAS can invest in a wide range of assets, including commercial property, which can provide opportunities for growth and income.</p>
                            ) : null}
                            <hr />
                            <h3 onClick={() => open(3)}>Company Benefits <span>{value === 3 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
                            {value === 3 ? (
                                <p>Business owners can use the SSAS to invest in their own company, potentially benefiting from increased company value and additional tax relief.</p>
                            ) : null}
                            <hr />
                            <h3 onClick={() => open(4)}>Tax Efficiency <span>{value === 4 ? <IoIosArrowDropdownCircle /> : <IoIosArrowDroprightCircle />}</span></h3>
                            {value === 4 ? (
                                <p>Contributions and investment growth within a SSAS are tax-efficient, helping you to maximise your retirement savings.</p>
                            ) : null}
                            <hr />
                        </div>
                    </section>
                    <h3>A SSAS offers a flexible and tax-efficient pension solution for business owners and key employees. With its HMRC-approved status providing tax advantages and broad investment opportunities, a SSAS can be a powerful tool for managing and growing your retirement savings. Understanding these features can help you determine if a SSAS aligns with your retirement goals.</h3>
                </section>
                <section className='book_call'>
                    <div className='call__inner'>
                        <h2>Want to know more?</h2>
                        <Button size='large' />
                    </div>
                </section>
                <Timeline />
            </section>
            <Footer />
        </main>
    )
}