import Footer from "./navAndFooter/Footer";
import Navigation from "./navAndFooter/Navigation";
import './styles/who.scss'
import Button from "./utils/Button";
import {useEffect} from 'react';
import about from './images/Info-SSAS.png';
import work from './images/Our Work-SSAS.png';
import know from './images/Knowledge-SSAS.png';

export default function Who() {
  useEffect(() => {window.scrollTo(0, 0)}, [])

  return (
    <main className='who__page'>
      <Navigation page='us' />
      <section className='who__main'>
        <section className='squares'>
          <section className='image__row'>
            <article>
              <p>As Independent SSAS Consultants, we work with various SSAS Professionals, SSAS Providers, Trustees, Administrators, Advisers and Technical experts and bring a whole range of resources and help together to make the SSAS process as simple as possible for all of our clients.</p>
            </article>
          </section>
          <section className='about_cards'>
            <div className='about__card'>
              <img src={know} alt='icon'/>
              <h6>Our expertise</h6>
              <p>With over <strong>36 years</strong> in financial services, our team excels in delivering tailored solutions for business and corporate clients. Our deep expertise allows us to adeptly handle complex SSAS requirements and offer personalised advice that aligns with your financial objectives</p>
            </div>
            <div className='about__card'>
            <img src={work} alt='icon'/>
              <h6>Our work</h6>
              <p>We've created a unique, integrated approach that covers all critical areas:</p>
              <ul>
                <li>Education: Providing essential knowledge for informed decisions.</li>
                <li>Application: Simplifying setup and registration processes.</li>
                <li>Administration: Ensuring efficient management and compliance.</li>
                <li>Planning: Developing strategies to meet your retirement goals.</li>
              </ul>
              <p>Our cohesive method combines these elements into a seamless, efficient experience.</p>
            </div>
            <div className='about__card'>
            <img src={about} alt='icon'/>
              <h6>Starting with SSAS</h6>
              <p>Discover how a SSAS can enhance your financial strategy. Our website provides an initial overview to help you get started. For tailored advice and <strong>to embark on your SSAS journey</strong>, contact us directly. Our team is ready to offer expert support and answer any questions</p>
            </div>
          </section>
        </section>
        <section className='book_call'>
          <h2>Do you want to take control of your pension now?</h2>
          <Button size='large' />
        </section>
      </section>
      <Footer />
    </main>
  )
}