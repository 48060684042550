import '../styles/navfooter.scss';
import Logo from '../images/logo.png';
import { useState, useEffect } from 'react';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const [date, setDate] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    const today = new Date();
    setDate(today.getFullYear());
  }, [])

  return (
    <footer>
      <img src={Logo} alt='logo' />
      <small><CopyrightIcon fontSize='small' /> {date} SSAS Business Enterprise Ltd. All rights reserved. SSAS Business Enterprise Ltd. work in collaboration with SSAS Consultant. Registered at Ground Floor 7 Cambuslang Way, Cambuslang Investment Park, Glasgow, G32 8ND. VAT Registration: GB426375488 Company Number: SC742454</small>
      <section className='subfooter'>
        <small onClick={() => navigate('/privacy-policy')}>Privacy Policy</small>
        <small onClick={() => navigate('/faq')}>FAQ</small>
      </section>
    </footer>
  )
}