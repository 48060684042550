import './styles/timeline.scss';
import { useEffect, useState } from 'react';
import bigTime from './images/time-big.webp';
import smallTime from './images/time-small.webp';

export default function Timeline() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 690) {
      setMobile(true)
    }
  }, [])

  return (
    <main className='timeline__main'>
      {!mobile ? (
        <section className='timeline'>
          <h2>Timeline</h2>
          <img src={bigTime} alt='timeline' />
        </section>
      ) : (
        <section className='timeline'>
          <h2>Timeline</h2>
          <img src={smallTime} alt='timeline' />
        </section>
      )}
    </main>
  )
}