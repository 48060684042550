import Footer from "./navAndFooter/Footer";
import Navigation from "./navAndFooter/Navigation";
import { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './styles/faq.scss'

export default function Faq() {
  const [showId, setShowId] = useState(0)

  const handleShow = (id) => {
    if (showId === id) {
      setShowId(0)
    } else {
      setShowId(id)
    }
  }

  return (
    <main className='faq__page'>
      <Navigation page='home' />
      <h2>FAQ about SSAS?</h2>
      <section className='tab__section'>
        <div className='tab__title' onClick={() => handleShow(1)}>
          <h3>Love the idea of SSAS and just don’t yet know enough about it?</h3>
          {showId === 1 ? <ArrowDropUpIcon className='title__icon' /> : <ArrowDropDownIcon className='title__icon' />}
        </div>
        {showId === 1 ? (
          <article className='open__tab'>
            <p>We speak to thousands of business owners every year who absolutely love the idea of SSAS, and have varying circumstances yet they ALL want to engage with us on SSAS for a variety of reasons and over differing timelines</p>
            <p>Now we have the ideal solution for you!</p>
            <p>Join our exclusive SSAS community and begin your SSAS education and development process here, with the comfort of knowing that you are being guided by specialists with decades of experience and allow us to assist you with SSAS no matter what stage you are at</p>
          </article>
        ) : null}
      </section>
      <section className='tab__section'>
        <div className='tab__title' onClick={() => handleShow(2)}>
          <h3>What is SSAS?</h3>
          {showId === 2 ? <ArrowDropUpIcon className='title__icon' /> : <ArrowDropDownIcon className='title__icon' />}
        </div>
        {showId === 2 ? (
          <article className='open__tab'>
            <p>SSAS – an incredibly well kept secret. Established in the UK In 1973, SSAS (Small Self Administered Scheme) is an Occupational type pension scheme, designed for businesses and their owners and directors.</p>
            <p>SSAS offers far more flexibility and a wider range of benefits and options than any other UK pension.</p>
            <p>SSAS allows business owners to use their pension plans and business in tandem to create robust long-term strategies for their pension, their business and their family.</p>
          </article>
        ) : null}
      </section>
      <section className='tab__section'>
        <div className='tab__title' onClick={() => handleShow(3)}>
          <h3>Who does SSAS suit?</h3>
          {showId === 3 ? <ArrowDropUpIcon className='title__icon' /> : <ArrowDropDownIcon className='title__icon' />}
        </div>
        {showId === 3 ? (
          <article className='open__tab'>
            <p>No two clients ever have the same circumstances or needs. SSAS is highly adaptable, offering a wide array of solutions and options to suit virtually all circumstances. SSAS is designed for business owners. Have a look and see the more typical uses that we usually see;</p>
            <ul>
              <li>Profitable businesses – SSAS with more than a single member, allows a business to contribute up to £500,000 per annum tax allowably</li>
              <li>Businesses who borrow money from a bank or other lender – SSAS allows a business to borrow up to 50%of the SSAS value to be used in the business</li>
              <li>People and business who already own commercial property – a SSAS can purchase and own commercial property, land or development land</li>
              <li>The SIPP holder – SSAS offers far more flexibility and options than a SIPP. Many business owners who are SIPP holders, change to SSAS</li>
              <li>The protection seeker – assets / value held within a SSAS is protected from litigation, tribunals, administration or liquidation or creditor actions</li>
              <li>The family business – SSAS by default creates a family trust, which provides an excellent way of succession planning and tax management</li>
              <li>The property / land investor – SSAS can purchase land for development and even structure a JV to develop that land, or sell the land tax efficiently with a changed planning status</li>
              <li>The Doctors / Dentists / Solicitors – Professionals have tended to seek flexible group pension structures, SSAS is ideally suited for grouped ownership structures such as solicitors, dentists and doctors</li>
            </ul>
          </article>
        ) : null}
      </section>
      <section className='tab__section'>
        <div className='tab__title' onClick={() => handleShow(4)}>
          <h3>Who can have a SSAS?</h3>
          {showId === 4 ? <ArrowDropUpIcon className='title__icon' /> : <ArrowDropDownIcon className='title__icon' />}
        </div>
        {showId === 4 ? (
          <article className='open__tab'>
            <p>SSAS is designed for UK business owners and the associated officers of the business, such as current, past or future Shareholders, Directors or Employees. The SSAS must be sponsored by an active trading company (not an investment company)</p>
          </article>
        ) : null}
      </section>
      <section className='tab__section'>
        <div className='tab__title' onClick={() => handleShow(5)}>
          <h3>What can I invest in?</h3>
          {showId === 5 ? <ArrowDropUpIcon className='title__icon' /> : <ArrowDropDownIcon className='title__icon' />}
        </div>
        {showId === 5 ? (
          <article className='open__tab'>
            <p>SSAS has an extremely wide scope of investment choice and is much more flexible than most other pension types. SSAS allows you to invest into an HMRC approved investment type. The choice of investment is entirely down to you as the Member Trustee of your own SSAS</p>
            <p>Unlike most pension structures that you are most likely familiar with, SSAS does not provide you with a prescribed investment portfolio or structure. All monies contributed or transferred into SSAS will be paid into the SSAS Bank account, and the choice of investment is thereafter entirely at your direction and discretion</p>
            <p>The SSAS Administrators and Professional Trustees are not investment advisers and take no part in the selection, provision or management of your investments</p>
            <ol>
              <li>You can loan money to your own Company or Companies or indeed to third party / unconnected companies.</li>
              <li>You can directly purchase commercial property or land </li>
              <li>You can seek any other HMRC approved investment which can include Bank accounts, Investment platforms, Trading platforms and so on</li>
            </ol>
          </article>
        ) : null}
      </section>
      <section className='tab__section'>
        <div className='tab__title' onClick={() => handleShow(6)}>
          <h3>Can I transfer other pensions into SSAS?</h3>
          {showId === 6 ? <ArrowDropUpIcon className='title__icon' /> : <ArrowDropDownIcon className='title__icon' />}
        </div>
        {showId === 6 ? (
          <article className='open__tab'>
            <p>Yes, SSAS can accept the transfers of existing pension benefits from a wide variety of sources. The simple rule is yes, IF that pension allows you to transfer out of the structure it’s currently in. The choice of electing to do that is entirely your own and SSAS does not require that you transfer any pension to it. We are not FCA regulated pension advisers and cannot advise on the suitability of transferring any pension.</p>
          </article>
        ) : null}
      </section>
      <Footer />
    </main>
  )
}