import { useEffect, useState } from "react"
import { getDate } from "./utils/utils"
import { InlineWidget } from "react-calendly";
import './styles/bookCall.scss'
import Navigation from "./navAndFooter/Navigation";
import Footer from "./navAndFooter/Footer";


export default function Calendar() {
  const [date, setDate] = useState('')
  const calendarStyles = {
    minWidth: '80%',
    height: '100vh',
    margin: '0 auto',
  }

  useEffect(() => {
    let thisMonth = getDate()
    setDate(thisMonth)
    window.scrollTo(0, 0)
  }, [date])

  return (
    <main className='call__main'>
      <Navigation page='call' />
      <h2>Select a time &amp; date to book your call</h2>
      <InlineWidget url={`https://calendly.com/paulbarry/15min-phone-call?month=${date}`}
      styles={calendarStyles}
      />

      <Footer />

    </main>
  )
}