import Footer from "./navAndFooter/Footer";
import Navigation from "./navAndFooter/Navigation";
import './styles/privacy.scss';
import { useEffect } from "react";

export default function Privacy(){

  useEffect(() => {window.scrollTo(0, 0)}, [])

  return(
    <main className='privacy__main'>
      <Navigation page='home'/>
      <section className='privacy__policy'>
        <h1>Privacy Policy</h1>
        <hr />
        <h4>Introduction</h4>
        <ol>
          <li>We are committed to safeguarding the privacy of our clients.</li>
          <li>This policy applies where we are acting as a data controller with respect to the personal data of our clients; in other words, where we determine the purposes and means of the processing of that personal data.</li>
          <li>We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.</li>
          <li>In this policy, “we”, “us” and “our” refer to SSAS Business Enterprise.</li>
        </ol>
        <h4>Credit</h4>
        <ol>
          <li>This document was created using a template from SEQ Legal <a href='https://seqlegal.com' target="_blank" rel="noreferrer">(https://seqlegal.com)</a>.</li>
        </ol>
        <h4>How do we use your personal data</h4>
        <ol>
          <li>We may process data about your use of our website and services (“usage data“). The usage data may include: your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is Google Analytics. This usage data may be processed for the purposes of analysing the use of the website and services. The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and services.</li>
          <li>We may process your account data (“account data“). The account data may include your name and email address. The source of the account data is you. The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business.</li>
          <li>We may process your information included in your personal profile on our website (“profile data“). The profile data may include [your name, address, telephone number, email address, profile pictures, gender, date of birth, relationship status, interests and hobbies, educational details and employment details.]The profile data may be processed for the purposes of enabling and monitoring your use of our website and services. The legal basis for this processing is consent OR our legitimate interests, namely the proper administration of our website and business OR the performance of a contract between you and us and/or taking steps, at you request, to enter into such a contract.</li>
          <li>We may process your personal data that are provided in the course of the use of our services (“service data“). The service data may include: your name; contact information; payment history; invoicing details; action reports; training vault access; testimonials; and background profile report. The source of the service data is you. The service data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is consent OR our legitimate interests, namely the proper administration of our website and business OR the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</li>
          <li>We may process information that you post for publication on our website or through our services (“publication data“). The publication data may be processed for the purposes of enabling such publication and administering our website and services. The legal basis for this processing is consent OR our legitimate interests, namely the proper administration of our website and business OR the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</li>
          <li>We may process information contained in any enquiry you submit to us regarding goods and/or services (“enquiry data“). The enquiry data may be processed for the purposes of offering, marketing and selling relevant goods and/or services to you. The legal basis for this processing is consent.</li>
          <li>We may process information relating to our customer relationships, including customer contact information (“customer relationship data“).[ The customer relationship data may include your name, your employer, your job title or role, your contact details, and information contained in communications between us and you. The source of the customer relationship data is you. The customer relationship data may be processed for the purposes of managing our relationships with customers, communicating with customers, keeping records of those communications and promoting our products and services to customers. The legal basis for this processing is our legitimate interests, namely the proper management of our customer relationships.</li>
          <li>We may process information relating to transactions, including purchases of goods and services, that you enter into with us and/or through our website (“transaction data“). The transaction data may include your contact details, your card details and the transaction details. The transaction data may be processed for the purpose of supplying the purchased goods and services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely the proper administration of our business and services.</li>
          <li>We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (“notification data“). The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis for this processing is consent.</li>
          <li>We may process information contained in or relating to any communication that you send to us (“correspondence data“). The correspondence data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.</li>
          <li>We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</li>
          <li>We may process any of your personal data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</li>
          <li>In addition to the specific purposes for which we may process your personal data set out in this Section 3, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</li>
          <li>Please do not supply any other person’s personal data to us, unless we prompt you to do so.</li>
        </ol>
        <h4>Providing your personal data to others</h4>
        <ol>
          <li>We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.</li>
          <li>We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</li>
          <li>In addition to the specific disclosures of personal data set out in this Section 4, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</li>
        </ol>
        <h4>International transfers of your personal data</h4>
        <ol>
          <li>In this Section 5, we provide information about the circumstances in which your personal data may be transferred to countries outside the European Economic Area (EEA).</li>
          <li>The hosting facilities for our website are situated in the United States of America. The European Commission has made an “adequacy decision” with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the European Commission.</li>
          <li>You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</li>
        </ol>
        <h4>Retaining and deleting personal data</h4>
        <ol>
          <li>This Section sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</li>
          <li>Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</li>
          <li>We will retain your personal data as follows:</li>
          <ul>
            <li>All personal data categories listed previously in this policy document will be retained for a minimum period of 1 year following the conclusion or termination of your membership with our services or programmes, and for a maximum period of 3 years following such a completion date.</li>
          </ul>
          <li>Notwithstanding the other provisions of this Section 6, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</li>
        </ol>
       <h4>Amendments</h4>
       <ol>
        <li>We may update this policy from time to time by publishing a new version on our website.</li>
        <li>You should check this page occasionally to ensure you are happy with any changes to this policy.</li>
        <li>We may notify you of significant changes to this policy by email.</li>
       </ol>
       <h4>Your rights</h4>
       <ol>
        <li>Right to access</li>
        <li>Right to rectification</li>
        <li>Right to erasure</li>
        <li>Right to restrict processing</li>
        <li>Right to object to processing</li>
        <li>Right to complain to a supervisory authority</li>
        <li>Right to withdraw consent</li>
       </ol>
       <h4>About Cookies</h4>
       <ol>
        <li>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</li>
        <li>Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</li>
        <li>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</li>
       </ol>
       <h4>Cookies that we Use</h4>
       <ol>
        <li>authentication – we use cookies to identify you when you visit our website and as you navigate our website;</li>
        <li>status – we use cookies to help us to determine if you are logged into our website;</li>
        <li>personalisation – we use cookies to store information about your preferences and to personalise the website for you;</li>
        <li>security – we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally;</li>
        <li>advertising – we use cookies to help us to display advertisements that will be relevant to you;</li>
        <li>analysis – we use cookies to help us to analyse the use and performance of our website and services;</li>
        <li>cookie consent – we use cookies to store your preferences in relation to the use of cookies more generally;</li>
       </ol>
       {/* TODO: our details and data protection officer */}
       {/* <h4>Our Details</h4>
       <h4>Data Protection Officer</h4> */}
      </section>
      <Footer />
    </main>
  )
}