import '../styles/navfooter.scss';
import Logo from '../images/logo.png';
// import { useNavigate } from 'react-router-dom'
import ButtonComp from '../utils/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

export default function Navigation({ page }) {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (page !== 'home') {
            document.getElementById(page).style.borderBottom = `2px solid #EB9624`;
        }
    }, [page])

    useEffect(() => {
        if (window.innerWidth < 690) {
            setMobile(true)
        }
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <nav>
            <section className='navigation'>
                <img src={Logo} alt='logo' width='15%' onClick={() => navigate('/')} />
                <section className='nav__links'>
                    {/* <ButtonComp size='small' /> */}
                    {!mobile ? (
                        <>
                            <p id='info' onClick={() => navigate('/')}>SSAS</p>
                            <p id='call' onClick={() => navigate('/book-a-call')}>Book a Call</p>
                            <p id='us' onClick={() => navigate('/about-us')}>About us</p>
                        </>
                    ) : (
                        <>

                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MenuOpenIcon sx={{ color: '#fff' }} fontSize='large' />
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => navigate('/')}>SSAS</MenuItem>
                                <MenuItem onClick={() => navigate('/book-a-call')}>Book a Call</MenuItem>
                                <MenuItem onClick={() => navigate('/about-us')}>About us</MenuItem>
                            </Menu>
                        </>
                    )}
                </section>
            </section>
        </nav>
    )
}